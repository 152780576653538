import React from 'react'
import Marquee from "react-fast-marquee";
import partner1 from "./partner-1.png"
import partner2 from "./partner-2.png"
import partner3 from "./partner-3.png"
import partner4 from "./partner-4.png"
import partner5 from "./partner-5.png"
import partner6 from "./partner-6.png"

const Footer = () => {
    return (
        <footer className="main-footer">
            <div className="footer-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-content-part-bottom">
                                <div className="company-list">
                                    <div className="scroller">
                                        <div className="scroller__inner">
                                            <Marquee>
                                                <a href="https://irtc.ca" target="_blank" rel="noreferrer"><img src={partner1} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-st-martin-first-nation" target="_blank" rel="noreferrer"><img src={partner2} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-manitoba-first-nation" target="_blank" rel="noreferrer"><img src={partner3} height="100px" width="auto" alt="" /></a>
                                                <a href="https://aactivecoin.com" target="_blank" rel="noreferrer"><img src={partner4} height="100px" width="auto" alt="" /></a>
                                                <a href="https://www.facebook.com/p/SONIX-BAR-GRILL-100063455565364/" target="_blank" rel="noreferrer"><img src={partner6} height="100px" width="auto" alt="" /></a>
                                                <a href="https://twentyplussix.com" target="_blank" rel="noreferrer"><img src={partner5} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca" target="_blank" rel="noreferrer"><img src={partner1} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-st-martin-first-nation" target="_blank" rel="noreferrer"><img src={partner2} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-manitoba-first-nation" target="_blank" rel="noreferrer"><img src={partner3} height="100px" width="auto" alt="" /></a>
                                                <a href="https://aactivecoin.com" target="_blank" rel="noreferrer"><img src={partner4} height="100px" width="auto" alt="" /></a>
                                                <a href="https://www.facebook.com/p/SONIX-BAR-GRILL-100063455565364/" target="_blank" rel="noreferrer"><img src={partner6} height="100px" width="auto" alt="" /></a>
                                                <a href="https://twentyplussix.com" target="_blank" rel="noreferrer"><img src={partner5} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca" target="_blank" rel="noreferrer"><img src={partner1} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-st-martin-first-nation" target="_blank" rel="noreferrer"><img src={partner2} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-manitoba-first-nation" target="_blank" rel="noreferrer"><img src={partner3} height="100px" width="auto" alt="" /></a>
                                                <a href="https://aactivecoin.com" target="_blank" rel="noreferrer"><img src={partner4} height="100px" width="auto" alt="" /></a>
                                                <a href="https://www.facebook.com/p/SONIX-BAR-GRILL-100063455565364/" target="_blank" rel="noreferrer"><img src={partner6} height="100px" width="auto" alt="" /></a>
                                                <a href="https://twentyplussix.com" target="_blank" rel="noreferrer"><img src={partner5} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca" target="_blank" rel="noreferrer"><img src={partner1} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-st-martin-first-nation" target="_blank" rel="noreferrer"><img src={partner2} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-manitoba-first-nation" target="_blank" rel="noreferrer"><img src={partner3} height="100px" width="auto" alt="" /></a>
                                                <a href="https://aactivecoin.com" target="_blank" rel="noreferrer"><img src={partner4} height="100px" width="auto" alt="" /></a>
                                                <a href="https://www.facebook.com/p/SONIX-BAR-GRILL-100063455565364/" target="_blank" rel="noreferrer"><img src={partner6} height="100px" width="auto" alt="" /></a>
                                                <a href="https://twentyplussix.com" target="_blank" rel="noreferrer"><img src={partner5} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca" target="_blank" rel="noreferrer"><img src={partner1} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-st-martin-first-nation" target="_blank" rel="noreferrer"><img src={partner2} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-manitoba-first-nation" target="_blank" rel="noreferrer"><img src={partner3} height="100px" width="auto" alt="" /></a>
                                                <a href="https://aactivecoin.com" target="_blank" rel="noreferrer"><img src={partner4} height="100px" width="auto" alt="" /></a>
                                                <a href="https://www.facebook.com/p/SONIX-BAR-GRILL-100063455565364/" target="_blank" rel="noreferrer"><img src={partner6} height="100px" width="auto" alt="" /></a>
                                                <a href="https://twentyplussix.com" target="_blank" rel="noreferrer"><img src={partner5} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca" target="_blank" rel="noreferrer"><img src={partner1} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-st-martin-first-nation" target="_blank" rel="noreferrer"><img src={partner2} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-manitoba-first-nation" target="_blank" rel="noreferrer"><img src={partner3} height="100px" width="auto" alt="" /></a>
                                                <a href="https://aactivecoin.com" target="_blank" rel="noreferrer"><img src={partner4} height="100px" width="auto" alt="" /></a>
                                                <a href="https://www.facebook.com/p/SONIX-BAR-GRILL-100063455565364/" target="_blank" rel="noreferrer"><img src={partner6} height="100px" width="auto" alt="" /></a>
                                                <a href="https://twentyplussix.com" target="_blank" rel="noreferrer"><img src={partner5} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca" target="_blank" rel="noreferrer"><img src={partner1} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-st-martin-first-nation" target="_blank" rel="noreferrer"><img src={partner2} height="100px" width="auto" alt="" /></a>
                                                <a href="https://irtc.ca/lake-manitoba-first-nation" target="_blank" rel="noreferrer"><img src={partner3} height="100px" width="auto" alt="" /></a>
                                                <a href="https://aactivecoin.com" target="_blank" rel="noreferrer"><img src={partner4} height="100px" width="auto" alt="" /></a>
                                                <a href="https://www.facebook.com/p/SONIX-BAR-GRILL-100063455565364/" target="_blank" rel="noreferrer"><img src={partner6} height="100px" width="auto" alt="" /></a>
                                                <a href="https://twentyplussix.com" target="_blank" rel="noreferrer"><img src={partner5} height="100px" width="auto" alt="" /></a>
                                            </Marquee>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </footer>
    )
}

export default Footer